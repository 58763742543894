<template>
  <vuestic-widget :loading="loading" class="automation-create-page">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Add New User</span>
        <div class="d-flex justify-content-end align-items-center">
        </div>
      </div>
    </template>
    <ValidationObserver 
      v-slot="{ handleSubmit, invalid, dirty }"
      ref="userCreateForm"
    >
      <form @submit.prevent="handleSubmit(onFormSubmit)" class="userCreateForm narrow-form1">
        <div class="row">
          <div class="col-md-12">
            <text-input name="First Name" v-model="formData.first_name" placeholder="First Name" label="First Name" validate="required" />
          </div>
          <div class="col-md-12">
            <text-input name="Last Name" v-model="formData.last_name" placeholder="Last Name" label="Last Name" validate="required" />
          </div>
          <div class="col-md-12">
            <text-input type="email" name="Email" placeholder="Enter email" v-model="formData.email" label="Email" validate="required|email"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <div class="control-label my-2">Send Registration Email</div>
            <vuestic-switch v-model="formData.send_email" :offcolor=true>
              <span slot="trueTitle">ON</span>
              <span slot="falseTitle">OFF</span>
            </vuestic-switch>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <router-link class="btn btn-primary btn-danger mr-2" :to="{name: 'admin.users.index'}">
              <span>Back</span>
            </router-link>
            <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" style="margin: auto;"/>
              <span v-else>Create</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-widget>
</template>

<script>
  import { mapState } from 'vuex';
  import TimeSelector from "../../forms/TimeSelector";

  export default {
    components: { 
      TimeSelector,
    },
    data() {
      return {
        loading: false,
        formData: {
          first_name: '',
          last_name: '',
          email: '',
          send_email: true,
        }
      };
    },

    created () {
    },

    computed: {
      ...mapState('user', {
        processing: 'processing',
      }),
    },
    mounted() {

    },
    methods: {
      onFormSubmit() {
        const param = {
          ...this.formData,
        }
        this.$store
          .dispatch('user/create', param)
          .then(() => {
            this.$router.push({ name: 'admin.users.index' });
          })
          .catch((err) => {
          })
      },
    },

  };
</script>

<style lang="scss" scoped>
  .userCreateForm {
    max-width: 768px;
  }
  .vuestic-switch {
    max-width: 200px;
    width: 200px;
  }
  .checkbox-label {
    --primaryColor: #3578c6;
  }
  .custom-checkbox-wrapper .checkbox-label .checkbox-custom {
    border-color: $text-gray;
    top: 4px;
    height: 17px;
    width: 17px;
  }

  .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom::after {
    left: 5px;
    top: 0px;
  }
</style>